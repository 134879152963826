<template>
  <v-row class="mx-0" :key="index" justify-md="center">
    <v-col cols sm="4">
      <horizontal-card
        :title="progressValue + '%'"
        subtitle="Progreso"
        mediaSize="50"
        color="neu-glow-inset-primary"
        iconColor="primary"
      />
    </v-col>
    <v-col cols sm="4">
      <horizontal-card
        :title="course.lessons_count ? course.lessons_count : 1"
        subtitle="Lecciones"
        mediaSize="50"
        color="neu-glow-inset-primary"
        iconColor="primary"
      />
    </v-col>
    <v-col cols sm="4">
      <horizontal-card
        :title="course.points ? course.points : 100"
        subtitle="Puntos "
        mediaSize="50"
        color="neu-glow-inset-primary"
        iconColor="primary"
      />
    </v-col>
  </v-row>
</template>
<script>
  import HorizontalCard from '@/components/UI/Card/HorizontalCard'
  export default {
    components: {
      HorizontalCard,
    },
    props: {
      course: {
        type: Object,
      },
    },
    computed: {
      progressValue() {
        if (this.course.lessons_count) {
          return Math.ceil(
            (this.course.lessons_completed_count / this.course.lessons_count) * 100
          )
        } else {
          return 0
        }
      },
    },
    data() {
      return {
        index: 1,
      }
    },
  }
</script>
