var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"neu-glow"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"height":"auto"},attrs:{"elevation":0}},[(_vm.setCover)?_c('v-img',{staticClass:"grey lighten-3",attrs:{"height":"200","aspect-ratio":4 / 3,"src":_vm.setCover,"lazy-src":_vm.setCover,"contain":""},on:{"click":function($event){return _vm.$router.push({
            name: 'business/elearning/UserLessonDetail',
            params: { lessonId: _vm.id },
          })}}},[_c('v-expand-transition',[(hover)?_c('v-overlay',{staticStyle:{"height":"100%","justify-content":"center","align-items":"center","background-color":"primary"},attrs:{"opacity":0.8,"color":"primary","absolute":""}},[_c('span',[_vm._v("Duración: "+_vm._s(_vm.duration)+" min")])]):_vm._e()],1)],1):_vm._e()],1)]}}])}),_c('div',{staticClass:"pos-relative"},[_c('v-btn',{staticStyle:{"bottom":"-5px"},attrs:{"absolute":"","dark":"","fab":"","right":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.$router.push({
            name: 'business/elearning/UserLessonDetail',
            params: { lessonId: _vm.id },
          })}}},[_vm._v(_vm._s(this.completed === 1 ? 'mdi-replay' : 'mdi-script-text-play'))])],1)],1),_c('h4',{staticClass:"pa-2",on:{"click":function($event){return _vm.$router.push({
        name: 'business/elearning/UserLessonDetail',
        params: { lessonId: _vm.id },
      })}}},[_vm._v(" "+_vm._s(_vm.name)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }