<template>
  <v-card class="neu-glow">
    <v-hover v-slot="{ hover }">
      <v-card :elevation="0" style="height: auto">
        <v-img
          @click="
            $router.push({
              name: 'business/elearning/UserLessonDetail',
              params: { lessonId: id },
            })
          "
          height="200"
          :aspect-ratio="4 / 3"
          :src="setCover"
          :lazy-src="setCover"
          contain
          class="grey lighten-3"
          v-if="setCover"
          ><v-expand-transition>
            <v-overlay
              :opacity="0.8"
              v-if="hover"
              color="primary"
              absolute
              style="
                height: 100%;
                justify-content: center;
                align-items: center;
                background-color: primary;
              "
            >
              <span>Duración: {{ duration }} min</span>
            </v-overlay>
          </v-expand-transition></v-img
        >
      </v-card>
    </v-hover>

    <div class="pos-relative">
      <v-btn absolute dark fab right x-small color="primary" style="bottom: -5px">
        <v-icon
          color="white"
          @click="
            $router.push({
              name: 'business/elearning/UserLessonDetail',
              params: { lessonId: id },
            })
          "
          >{{ this.completed === 1 ? 'mdi-replay' : 'mdi-script-text-play' }}</v-icon
        >
      </v-btn>
    </div>
    <h4
      class="pa-2"
      @click="
        $router.push({
          name: 'business/elearning/UserLessonDetail',
          params: { lessonId: id },
        })
      "
    >
      {{ name }}
    </h4>
  </v-card>
</template>

<script>
  export default {
    name: 'UserCourseCard',
    computed: {
      progressValue() {
        if (this.lessons_count) {
          return Math.ceil((this.lessons_completed_count / this.lessons_count) * 100)
        } else {
          return 0
        }
      },
      progressColor() {
        if (this.completed === 1) {
          return 'success'
        } else {
          return 'primary'
        }
      },
      courseStatus() {
        if (this.completed === 1) {
          return 'Lección completado!'
        } else {
          return 'Continuar curso ->'
        }
      },
      setCover() {
        if (this.cover && this.cover.route) {
          return this.cover.route
        } else if (this.course.cover && this.course.cover.route) {
          return this.course.cover.route
        } else {
          return ''
        }
      },
    },
    props: {
      id: {
        type: Number,
        default: 0,
      },
      cover: {
        type: Object,
        default: null,
      },
      course: {
        type: Object,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      duration: {
        type: [Number, String],
        default: 0,
      },
      completed: {
        type: Number,
        default: 0,
      },
    },
  }
</script>
<style lang="scss" scoped></style>
