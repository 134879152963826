var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vuse-content-wrapper"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('smart-breadcrumbs',{attrs:{"items":_vm.navigationElements}}),_c('help',{staticClass:"mx-2",staticStyle:{"vertical-align":"baseline"},attrs:{"helpId":51,"xSmall":true}})],1),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-2 mx-0"},[_c('v-col',{staticClass:"align-self-center pa-0",attrs:{"align":"center"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.course.name)+" ")])])],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12","lg":"9","md":"8"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":0,"height":"250"}},[(_vm.course.cover && _vm.course.cover.route)?_c('v-img',{staticClass:"grey lighten-3",attrs:{"height":"250","aspect-ratio":4 / 3,"src":_vm.course.cover.route,"lazy-src":_vm.course.cover.route,"contain":""}},[_c('v-expand-transition',[(hover)?_c('v-overlay',{staticStyle:{"height":"100%","justify-content":"center","align-items":"center","background-color":"primary"},attrs:{"opacity":0.8,"color":"primary","absolute":""}},[_c('span',[_vm._v("Lecciones completadas: "+_vm._s(_vm.course.lessons_completed_count)+"/"+_vm._s(_vm.course.lessons_count)+" ")]),_c('br'),_c('span',[_vm._v("Duración del curso: "+_vm._s(_vm.course.duration)+" min")])]):_vm._e()],1)],1):_vm._e()],1)]}}])}),_c('course-stats',{staticClass:"mt-4",attrs:{"course":_vm.course}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4"}},[_c('v-sheet',{staticClass:"neu-glow"},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-btn',{attrs:{"icon":"","color":"blue","small":""}},[_c('v-icon',[_vm._v("public")])],1),_c('v-toolbar-title',[_vm._v("Descripción")]),_c('v-spacer')],1),_c('v-card-text',[_c('div',{staticClass:"text-body-2",domProps:{"innerHTML":_vm._s(_vm.course.description)}})]),(_vm.course.previous && _vm.course.previous.id)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Curso previo necesario: ")]),_c('v-list-item-subtitle',[_c('router-link',{attrs:{"to":{
                    name: 'business/elearning/UserCourseDetail',
                    params: { courseId: _vm.course.previous.id },
                  }}},[_vm._v(_vm._s(_vm.course.previous.name))])],1)],1)],1):_vm._e()],1),(_vm.progressValue === 100)?_c('v-card',{staticClass:"mx-auto my-2",attrs:{"color":"transparent","max-width":"300"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","small":"","color":"primary","height":"35px","max-width":"200"},on:{"click":function($event){_vm.reveal = !_vm.reveal}}},[_vm._v(" Realizar examen ")])],1),_c('v-expand-transition',[(_vm.reveal)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%"},attrs:{"color":"transparent","flat":""}},[(_vm.course && _vm.course.exam)?_c('v-card-text',{staticClass:"pb-0"},[_c('span',{staticClass:"text--primary"},[_vm._v(" Duración: "+_vm._s(_vm.course.exam.duration)+" min "),_c('br'),_vm._v(" Intentos: "+_vm._s(_vm.course.exam.attempts)+" ")]),_c('br'),(_vm.course.exam.questions)?_c('span',{staticClass:"text--primary"},[_vm._v(" Preguntas: "+_vm._s(_vm.course.exam.questions.length)+" ")]):_vm._e()]):_vm._e(),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"text":"","color":"teal accent-4"},on:{"click":function($event){return _vm.$router.push({
                      name: 'business/elearning/UserExam',
                      params: { examId: _vm.course.exam.id },
                    })}}},[_vm._v(" Iniciar ")])],1)],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('v-row',{staticClass:"mx-0"},_vm._l((_vm.lessons),function(lesson){return _c('v-col',{key:lesson.id,attrs:{"cols":"12","xl":"3","md":"4","sm":"6"}},[_c('lesson-card',_vm._b({attrs:{"loading":_vm.loading,"course":_vm.course}},'lesson-card',lesson,false))],1)}),1),_c('div',{staticClass:"text-center pt-2",staticStyle:{"margin":"10px 0px"}},[_c('v-pagination',{attrs:{"color":"primary","length":_vm.pages},on:{"input":function($event){return _vm.pagination($event)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }