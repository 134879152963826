<template>
  <div class="vuse-content-wrapper">
    <v-row no-gutters align="center" justify="space-between">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="mx-2" :helpId="51" :xSmall="true" style="vertical-align: baseline"
    /></v-row>
    <v-container class="py-0" fluid>
      <v-row class="my-2 mx-0">
        <v-col class="align-self-center pa-0" align="center">
          <h2>
            {{ course.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="12" lg="9" md="8">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="0" height="250">
              <v-img
                height="250"
                :aspect-ratio="4 / 3"
                :src="course.cover.route"
                :lazy-src="course.cover.route"
                contain
                class="grey lighten-3"
                v-if="course.cover && course.cover.route"
                ><v-expand-transition>
                  <v-overlay
                    :opacity="0.8"
                    v-if="hover"
                    color="primary"
                    absolute
                    style="
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      background-color: primary;
                    "
                  >
                    <span
                      >Lecciones completadas: {{ course.lessons_completed_count }}/{{
                        course.lessons_count
                      }} </span
                    ><br />
                    <span>Duración del curso: {{ course.duration }} min</span>
                  </v-overlay>
                </v-expand-transition></v-img
              ></v-card
            >
          </v-hover>
          <course-stats class="mt-4" :course="course" />
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <v-sheet class="neu-glow">
            <v-toolbar dense flat color="transparent">
              <v-btn icon color="blue" small>
                <v-icon>public</v-icon>
              </v-btn>
              <v-toolbar-title>Descripción</v-toolbar-title>

              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <div class="text-body-2" v-html="course.description" />
            </v-card-text>
            <v-list-item two-line v-if="course.previous && course.previous.id">
              <v-list-item-content>
                <v-list-item-title>Curso previo necesario: </v-list-item-title>
                <v-list-item-subtitle
                  ><router-link
                    :to="{
                      name: 'business/elearning/UserCourseDetail',
                      params: { courseId: course.previous.id },
                    }"
                    >{{ course.previous.name }}</router-link
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
          <v-card
            color="transparent"
            class="mx-auto my-2"
            max-width="300"
            v-if="progressValue === 100"
          >
            <v-card-actions>
              <v-btn
                block
                small
                color="primary"
                height="35px"
                max-width="200"
                @click="reveal = !reveal"
              >
                Realizar examen
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                color="transparent"
                flat
                v-if="reveal"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0" v-if="course && course.exam">
                  <span class="text--primary">
                    Duración: {{ course.exam.duration }} min <br />
                    Intentos: {{ course.exam.attempts }} </span
                  ><br />
                  <span class="text--primary" v-if="course.exam.questions">
                    Preguntas: {{ course.exam.questions.length }}
                  </span>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    class="mx-auto"
                    text
                    color="teal accent-4"
                    @click="
                      $router.push({
                        name: 'business/elearning/UserExam',
                        params: { examId: course.exam.id },
                      })
                    "
                  >
                    Iniciar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="12" xl="3" md="4" sm="6" v-for="lesson in lessons" :key="lesson.id">
          <lesson-card :loading="loading" v-bind="lesson" :course="course" />
        </v-col>

        <!-- <v-col cols="3">
          <two-cols-stats />
        </v-col> -->
      </v-row>
      <div class="text-center pt-2" style="margin: 10px 0px">
        <v-pagination
          @input="pagination($event)"
          color="primary"
          v-model="page"
          :length="pages"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
  // import TwoColsStats from '@/views/Dashboards/AnalyticalDashboard/Partials/TwoColsStats'
  import LessonCard from '@/components/Elearning/LessonCard'
  import CourseStats from '@/components/Elearning/CourseStatistics'
  export default {
    components: {
      LessonCard,
      CourseStats,
    },
    data() {
      return {
        reveal: false,
        tab: 0,
        course: {},
        lessons: [],
        page: 1,
        pages: 1,
        loading: false,
        totalItems: 0,
        itemsPerPage: 10,
        loadingText: 'Cargando cursos',
        filterActivated: false,
        navigationElements: [
          {
            text: 'Learning',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/UserPanel',
            href: '/business/elearning/user-panel',
          },
          {
            text: 'Mis Cursos',
            disabled: false,
            query: null,
            params: null,
            name: '',
            href: '/business/elearning/user-panel/courses',
          },
          {
            text: 'Detalles del curso',
            disabled: true,
          },
        ],
      }
    },
    computed: {
      progressValue() {
        if (this.course.lessons_count) {
          return Math.ceil(
            (this.course.lessons_completed_count / this.course.lessons_count) * 100
          )
        } else {
          return 0
        }
      },
    },
    created() {
      this.getCourse()
      this.getLessons()
    },
    methods: {
      async getCourse() {
        await this.$axios
          .get(
            'courses/' +
              this.$route.params.courseId +
              '?with[]=cover&appends[]=lessons_count&appends[]=lessons_completed_count&appends[]=duration&with[]=exam&appends[]=completed'
          )
          .then((response) => {
            this.course = response.data
          })
      },
      async getLessons() {
        this.loading = true
        let queryParams = ''
        const orderString = '&order=order&by=asc'
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'lessons?with[]=cover&with[]=uploads&course_id=' +
              this.$route.params.courseId +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.lessons = response.data.data
            if (this.lessons.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
          })
      },
      async pagination(page) {
        this.page = page
        this.lessons = []
        await this.getLessons()
      },
    },
    beforeDestroy() {
      this.courseData = null
      delete this.courseData
    },
  }
</script>
<style lang="scss">
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
  .v-breadcrumbs__item {
    font-size: 12px;
  }
</style>
